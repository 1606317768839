export default {
  c1: `
    name: 'MyComponent',

    delimiters: ['<%','%>'],

    functional: false,

    model: {
      prop: 'checked',
      event: 'change'
    }
  `,

  c2: `
    components: {
      OtherComponent
    },

    directives: {
      MyDirective
    },

    filters: {
      myFilter1(value) {
        return value
      }
    }
  `,

  c3: `
    mixins: [ myMixin ],

    extends: myComponent2,

    provide () {
      return {
        [s]: 'foo'
      }
    },

    inject: { s }
  `,

  c4: `
    el: '#app',

    template: '<div></div>'
  `,

  c5: `
    props: {
      some: {
        type: String | Object,

        required: true,

        // if required false, can default
        default: () => {},

        validator: value => value
      }
    },

    // propsData is most often used for tests
    propsData: {
      some: 'hello'
    }
  `,

  c6: `
    data: () => ({
      some: 1
    })
  `,
  c7: `
    computed: {
      someComputed() {
         return 1
      }
    }
    // or if you use mapstate
    computed: mapState({
      someValue = state => state.someValue
    })
    // and spread
    computed: {
      someComputed() {
        return 1
      },
      ...mapState({
        someValue = state => state.someValue
      })
    }
  `,
  c8: `
    watch: {
      some(newValue, oldValue) {
        //...
      }
    }
  `,
  c9: `
    methods: {
      someMethod() {
        //...
      }
    }
  `,
  c10: `
    beforeCreate() {
      //...
    },

    created() {
      //...
    },

    beforeMount() {
      //...
    },

    mounted() {
      //...
    },

    beforeUpdate() {
      //...
    },

    updated() {
      //...
    },

    activated() {
      //...
    },

    deactivated() {
      //...
    },

    beforeDestroy() {
      //...
    },

    destroyed() {
      //...
    },

    errorCaptured: (error) => {
      console.log(error)
    }
  `,
  c11: `
    render: (createElement) => {
      return ''
    },
  
    renderError () {
      return ''
    }
  `
}
