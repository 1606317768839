<template>
  <div class="ra-basic ra-basic-text">
    <h2>Right way to ordering things inside a component</h2>
    <p>
      Options, assets, composition etc
    </p>
    <div>
      <h3>Content:</h3>
      <ul class="ra-ul-numbered">
        <li>
          <RaLink
            link="#options"
            target="_self"
            follow
            styled
          >
            Options
          </RaLink>
        </li>
        <li>
          <RaLink
            link="#assets"
            target="_self"
            follow
            styled
          >
            Assets
          </RaLink>
        </li>
        <li>
          <RaLink
            link="#composition"
            target="_self"
            follow
            styled
          >
            Composition
          </RaLink>
        </li>
        <li>
          <RaLink
            link="#element"
            target="_self"
            follow
            styled
          >
            Element
          </RaLink>
        </li>
        <li>
          <RaLink
            link="#props"
            target="_self"
            follow
            styled
          >
            Props
          </RaLink>
        </li>
        <li>
          <RaLink
            link="#data"
            target="_self"
            follow
            styled
          >
            Data
          </RaLink>
        </li>
        <li>
          <RaLink
            link="#computed"
            target="_self"
            follow
            styled
          >
            Computed
          </RaLink>
        </li>
        <li>
          <RaLink
            link="#watch"
            target="_self"
            follow
            styled
          >
            Watch
          </RaLink>
        </li>
        <li>
          <RaLink
            link="#methods"
            target="_self"
            follow
            styled
          >
            Methods
          </RaLink>
        </li>
        <li>
          <RaLink
            link="#hooks"
            target="_self"
            follow
            styled
          >
            Lifecycle hooks
          </RaLink>
        </li>
        <li>
          <RaLink
            link="#render"
            target="_self"
            follow
            styled
          >
            Render
          </RaLink>
        </li>
      </ul>
      <br />
      <br />
      Ready? Lets go:
      <br />
      <h3 id="options">
        1. Options
      </h3>
      <RaCode>
        {{ code.c1 }}
      </RaCode>
      <br />
      <h3 id="assets">
        2. Assets
      </h3>
      <RaCode>
        {{ code.c2 }}
      </RaCode>
      <br />
      <h3 id="composition">
        3. Composition
      </h3>
      <RaCode>
        {{ code.c3 }}
      </RaCode>
      Why I love <b>Mixins</b>:
      <RaLink
        link="https://ralmaz.pro/blog/mixins"
        follow
        styled
      >
        Mixins - elegant work with API
      </RaLink>
      <br />
      <h3 id="element">
        4. Element
      </h3>
      <RaCode>
        {{ code.c4 }}
      </RaCode>
      <br />
      <h3 id="props">
        5. Props
      </h3>
      <RaCode>
        {{ code.c5 }}
      </RaCode>
      More about default property in Props:
      <RaLink
        link="https://ralmaz.pro/blog/default-property"
        follow
        styled
      >
        Default in nutshell
      </RaLink>
      <br />
      <h3 id="data">
        6. Data
      </h3>
      <RaCode>
        {{ code.c6 }}
      </RaCode>
      <br />
      <h3 id="computed">
        7. Computed
      </h3>
      <RaCode>
        {{ code.c7 }}
      </RaCode>
      <br />
      <h3 id="watch">
        8. Watch
      </h3>
      <RaCode>
        {{ code.c8 }}
      </RaCode>
      Recomendation: <b>DONT</b> use <b>Watch</b> if you can
      <br />
      More about why:
      <RaLink
        link="https://ralmaz.pro/blog/watcher"
        follow
        styled
      >
        Watcher memory hell
      </RaLink>
      <br />
      <h3 id="methods">
        9. Methods
      </h3>
      <RaCode>
        {{ code.c9 }}
      </RaCode>
      <br />
      <h3 id="hooks">
        10. Lifecycle hooks
      </h3>
      <RaCode>
        {{ code.c10 }}
      </RaCode>
      <br />
      <h3 id="render">
        11. Render
      </h3>
      <RaCode>
        {{ code.c11 }}
      </RaCode>
      <br />
      <br />
      This ordering provided by <b>Evan You</b>,
      <br />
      in <b>Discord</b> server
      <RaLink
        link="https://vue.land/"
        styled
      >
        VueLand
      </RaLink>
      <br />
      <br />
      stay tuned
      <br />
      Bye bye
    </div>
    <br />
    <p>
      {{ authors.length > 1 ? 'Authors:' : 'Author:' }}
      <RaLink
        v-for="(author, key) in authors"
        :key="key"
        :link="author.link"
        follow
        styled
      >
        {{ author.nick }}
      </RaLink>
    </p>
    <div
      v-if="vote && vote.up >= 0 && vote.down >= 0"
      class="ra-vote-block"
    >
      <RaButton
        v-title="`${vote.up} votes`"
        vote
        vote-type="up"
        type="success"
        class="ra-vote-button"
        @click="makeVote(postId, true)"
      />
      <RaButton
        v-title="`${vote.down} votes`"
        vote
        vote-type="down"
        type="danger"
        class="ra-vote-button"
        @click="makeVote(postId, false)"
      />
    </div>
  </div>
</template>

<script>
import VTitle from 'v-title'

import RaCode from '@/components/elements/RaCode.vue'
import RaLink from '@/components/elements/RaLink.vue'
import RaButton from '@/components/elements/RaButton.vue'

import fingerVote from '@/mixins/fingerVote'

import codeData from '@/components/articles/Ordering/code'

export default {
  name: 'Ordering',

  components: {
    RaCode,
    RaLink,
    RaButton
  },

  directives: {
    title: VTitle
  },

  mixins: [fingerVote],

  metaInfo: {
    title: 'Right way ordering things inside a component',
    titleTemplate: '%s | Roman Almazov',

    meta: [
      {
        name: 'description',
        content: 'Options, assets, composition etc'
      },
      {
        name: 'og:title',
        content: 'Right way ordering things inside a component'
      },
      {
        name: 'twitter:title',
        content: 'Right way ordering things inside a component'
      },
      {
        name: 'og:description',
        content: 'Options, assets, composition etc'
      },
      {
        name: 'twitter:description',
        content: 'Options, assets, composition etc'
      },
      { name: 'og:url', content: 'https://ralmaz.pro/blog/ordering' },
      {
        name: 'twitter:url',
        content: 'https://ralmaz.pro/blog/ordering'
      },

      { name: 'og:type', content: 'article' },
      { name: 'article:published_time', content: '2020-05-10' },
      { name: 'article:modified_time', content: '2020-07-02' },
      { name: 'article:author', content: 'RALMAZ' },
      { name: 'article:section', content: 'Vue.js' },
      { name: 'article:tag', content: 'Vue.js' },
      { name: 'article:tag', content: 'JavaScript' }
    ]
  },

  data: () => ({
    postId: 2,
    createdAt: '2020-05-10',
    authors: [
      {
        nick: '@RALMAZ',
        link: 'https://ralmaz.pro'
      }
    ]
  }),

  computed: {
    code() {
      return codeData
    }
  },

  mounted() {
    this.initFingerVote(this.postId)
  }
}
</script>
